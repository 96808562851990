import React, { useEffect, useContext} from 'react';
import { FabContext } from "../../context/FabContext";
import Layout from "../../components/layout"

const Expanded = (bride) => {

    const {name,url,text} = bride.pageContext.bride;
    const{setLocal} = useContext(FabContext);

    useEffect(()=>{ setLocal('expanded'); },[]);

    return(
        <Layout>
            <div className="expanded">
                <div style={{backgroundImage: `url(../images/testimonial-focus/${url})`}} className="featured" />
                    <div className="text-container"> 
                        <p dangerouslySetInnerHTML={{__html:text}}></p>
                        <h5>{name}</h5>
                </div>
            </div>
        </Layout>
    )
}
export default Expanded;
